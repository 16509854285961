<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('photo.title')"></h1>
      <p>{{ $t('photo.subtitle') }}</p>
    </div>
    <div class="smenu">
      <div class="moment_btn">
        <a v-for="(type, idx) in typeList" :key="idx" href="javascript:" :class="{ now: idx == selectTypeIdx }"
          @click="onType(idx)">
          {{ type.name }}
        </a>
      </div>
      <div class="moment_search_hashtag_create">
        <div class="moment_search">
          <a href="javascript:" @click="onSearch()">
            <img src="../../assets/img/search.png">
          </a>
          <input type="text" v-model="searchKeyword" :placeholder="$t('country.search')"  v-on:keyup.enter="onSearch()"/>
        </div>
        <div class="moment_hashtag">
          <a href="javascript:" @click="onHashtagSelect()">
            {{ $t('photo.hashtag') }}
          </a>
        </div>
        <div class="moment_create">
          <a href="javascript:" @click="onWrite()">
            <img src="../../assets/img/ico_create.png">{{ $t('photo.create') }}
          </a>
        </div>
      </div>
    </div>

    <div v-if="selectedHashtags.length > 0" class="hashtags">
      <ul class="hashtag_list">
        <li v-for="hashtag in selectedHashtags" class="hashtag_item selected">
          <a href="javascript:">#{{ hashtag }}</a>
        </li>
      </ul>
    </div>

    <div class="warp">
      <div v-if="myInfo != null" class="follower_list">
        <ul>
          <li v-for="(user, idx) in followingUsers" :key="idx">
            <a href="javascript:" @click="onProfile(user)">
              <UserImage :gender="user.user_sex" :image="user.user_profile_image" />
              <div class="nickname_gender_age">
                <div class="nickname">{{ user.user_nickname }}</div>
                <div class="gender_age" :class="[{ male: user.user_sex == 0, female: user.user_sex == 1 }]">({{
                  (user.user_sex == 1
                    ? $t('common.female_short') : $t('common.male_short')) + user.user_age }})</div>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div class="moment_list">
        <ul>
          <li v-for="(photo, idx) in photoList" :key="idx">
            <a href="javascript:" @click="onCell(idx)">
              <img v-if="photo.image != ''" v-lazy="getPatchedVideoThumbnailUrl(photo)">
              <img v-if="photo.media_type == 0 && photo.image.split('###').length > 1"
                src="../../assets/img/story_item_type_images.png" class="media_type_tag">
              <img v-if="photo.media_type == 1" src="../../assets/img/story_item_type_video.png" class="media_type_tag">
              <img v-if="photo.media_type == 2" src="../../assets/img/story_item_type_audio.png" class="media_type_tag">
              <h2>
                <img style="width: 18px; height: 15px; margin-top: -5px;"
                  :src="require('../../assets/flag/' + photo.user_country_code + '.png')">
                {{ photo.user_nickname }}
                <span :class="[{ male: photo.user_sex == '0', female: photo.user_sex == '1' }]">
                  ({{
                    (photo.user_sex == '0' ? $t('common.male_short') : $t('common.female_short')) + photo.user_age
                  }})
                </span>
              </h2>
              <p>
                <span><img src="../../assets/img/ico_view.png">{{ currency(photo.hits) }}</span>
                <span><img src="../../assets/img/ico_recommend.png">{{ currency(photo.recommends) }}</span>
              </p>
            </a>
          </li>
          <li>
            <InfiniteLoading :first-load="false" @infinite="getList" />
          </li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
  </div>

  <CreateMomentStep1 :show="showCreateMomentStep1" @close="showCreateMomentStep1 = false"
    @next="onCreateMomentStep1Next" />

  <CreateMomentStep2 :show="showCreateMomentStep2" :images="createMomentStep1Images"
    :imageDatas="createMomentStep1ImageDatas" @close="showCreateMomentStep2 = false" @done="onCreateMomentStep2Done" />

  <MomentDetail :show="showMomentDetail" :data="photoList" :index="selectMomentIdx" @close="showMomentDetail = false" />

  <HashtagSelectPopup :show="showHashtagSelectPopup" @close="showHashtagSelectPopup = false"
    @selected="onHashtagSelected" />
</template>

<style lang="scss" scoped src="./Moment.scss"></style>

<script>
import { EVENT_NAME, PAGE_SIZE } from "../../lib/constant";
import { requestPost, showToast } from "@/lib/util";
import InfiniteLoading from 'v3-infinite-loading';
import CreateMomentStep1 from "@/views/moment/create_moment/CreateMomentStep1";
import CreateMomentStep2 from "@/views/moment/create_moment/CreateMomentStep2";
import MomentDetail from "@/views/moment/MomentDetail";
import HashtagSelectPopup from './create_moment/HashtagSelect.vue';
import UserImage from "../../components/UserImage";

export default {
  name: "Moment",
  components: {
    MomentDetail,
    InfiniteLoading,
    CreateMomentStep1,
    CreateMomentStep2,
    HashtagSelectPopup,
    UserImage,
  },
  data() {
    let myInfo = this.$store.getters.me;

    return {
      myInfo: myInfo,
      selectTypeIdx: 0,
      countryCode: '',
      searchKeyword: '',
      photoList: [],
      loadingEnd: false,
      loading: false,
      typeList: [
        { code: 'all1', name: 'ALL' },
        { code: 'video', name: 'VIDEO' },
        { code: 'audio', name: 'AUDIO' },
        { code: 'me', name: 'MY' },
      ],
      category: 0,
      followingUsers: [],
      showHashtagSelectPopup: false,
      selectedHashtags: [],

      showCreateMomentStep1: false,
      showCreateMomentStep2: false,
      createMomentStep1Images: null,
      createMomentStep1ImageDatas: null,

      selectMomentIdx: 0,
      photoInfo: {},
      showMomentDetail: false,
    }
  },
  mounted() {
    this.photoList = [];
    this.loadingEnd = false;
    this.getFollowUsers();
    this.emitter.on(EVENT_NAME.REPORT_SUCCESS, this.initList);
  },
  beforeUnmount() {
    this.emitter.off(EVENT_NAME.REPORT_SUCCESS, this.initList);
  },
  methods: {
    getImageThumbnailUri: function (imageUrl) {
      if (imageUrl.startsWith('file://')) {
        return imageUrl;
      }

      return imageUrl ? imageUrl.replace('/image_', '/s_image_') : '';
    },
    onType: function (idx) {
      this.myInfo = this.$store.getters.me; 

      if (idx == 3) {
        if (this.myInfo == null) {     
          this.$router.replace({
            name: 'Logout'
          });
          return;
        }
      }
      this.selectTypeIdx = idx;
      this.initList();
    },
    onProfile: function (user) {
      this.$root.showUserDlg(user);
    },
    onCell: function (idx) {
      window.localStorage.setItem('Album.UserInfo', JSON.stringify(this.photoList[idx]));
      this.$router.push('/album/' + this.photoList[idx].uid);
    },
    onSearch: function () {
      this.initList();
    },
    onHashtagSelect: function () {
      this.myInfo = this.$store.getters.me; 

      if (this.myInfo == null) {      
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      this.showHashtagSelectPopup = true;
    },
    onHashtagSelected: function (hashtags) {
      this.selectedHashtags = hashtags;
      this.showHashtagSelectPopup = false;
      this.initList();
    },
    onWrite: function () {
      this.myInfo = this.$store.getters.me; 

      if (this.myInfo == null) {      
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      this.showCreateMomentStep1 = true;
    },
    onCreateMomentStep1Next: function (images, imageDatas) {
      this.showCreateMomentStep1 = false;
      this.createMomentStep1Images = images;
      this.createMomentStep1ImageDatas = imageDatas;
      this.showCreateMomentStep2 = true;
    },
    onCreateMomentStep2Done: function () {
      this.showCreateMomentStep2 = false;
    },
    initList: function () {
      this.photoList = [];
      this.loadingEnd = false;

      this.getList();
      this.getFollowUsers();
    },
    getList: function ($state) {
      let _app = this;

      if (this.loadingEnd || this.loading) {
        return;
      }

      this.loading = true;
      requestPost(
        this.myInfo == null ? 'zzalbe/getZzalbesWithoutLogin' : 'zzalbe/getZzalbes',
        {
          keyword: this.searchKeyword,
          country_code: this.countryCode,
          category: 0,
          uid: this.myInfo == null ? 0 : this.myInfo.uid,
          type: this.typeList[this.selectTypeIdx].code,
          latitude: 0,
          longitude: 0,
          hash_tags: this.selectedHashtags.join(','),
          offset: this.photoList.length,
          limit: PAGE_SIZE
        },
        response => {
          _app.photoList = _app.photoList.concat(response.zzalbes);
          _app.loadingEnd = response.zzalbes.length < PAGE_SIZE;
          if ($state) {
            if (_app.loadingEnd) {
              $state.complete();
            }
            $state.loaded();
          }
          _app.loading = false;
        },
        (code, msg) => {
          showToast(msg);
          if ($state) {
            $state.loaded();
          }
          _app.loading = false;
        }
      );
    },
    getFollowUsers: function () {
      if (this.myInfo == null) {
        return;
      }
      
      let _app = this;

      requestPost(
        'jjim/readJjimWithZzalbes',
        {
          uid: this.myInfo.uid,
          offset: 0,
          limit: 20
        },
        response => {
          _app.followingUsers = response.jjim_list;
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    getThumbImage: function (image, index) {
      if (image == '') {
        return '';
      }

      let imageUrlList = image.split('###');
      if (this.index >= imageUrlList.length) {
        return '';
      }

      return this.getImageThumbnailUri(imageUrlList[index]);
    },
    getPatchedVideoThumbnailUrl: function(photo) {
      let image = photo.image;

      //
      // [2024/07/24 20:14 KSH]7.15일 이전까지는 동영상 썸네일 URL형식이 잘못되였었음.
      //
      if (photo.regtime > '2024-07-15') {
        return image;
      }

      if (image.includes('profile_videos') && !image.includes('video_')) {
        return image.replace('.mp4_thumbnail.jpg', '_thumbnail.jpg');
      }
      return image;
    }
  },
}
</script>

